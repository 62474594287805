import AeditLink from 'services/link'
import { Icon } from 'components/icon/Icon'
import DropdownMenu from 'components/dropdown'
import ProfileLink from 'components/profile_link'
import LogoutLink from 'components/logout'

const AuthMenu = ({
  isShown,
  setShown,
  setClosed,
  currentUser,
  toggleProviderAuth,
  toggleCandidateAuth,
}) => (
  <DropdownMenu
    className="absolute top-full bg-white p-8 w-max right-0 rounded-b-2xl overflow-hidden"
    data-cy="hover-menu"
    isShown={isShown}
    setClosed={setClosed}
    onMouseEnter={setShown}>
    <DropdownMenu.Box className="text-black">
      {currentUser ? (
        <ul>
          <li className="flex mb-4 transition-all hover:opacity-50">
            <ProfileLink className="flex" data-cy="profile-link">
              <Icon name="edit-pen" className="mr-2" />
              Manage Profile
            </ProfileLink>
          </li>
          <li className="flex mb-4 transition-all hover:opacity-50">
            <LogoutLink className="flex">
              <Icon name="log-out" className="mr-2" />
              Log Out
            </LogoutLink>
          </li>
        </ul>
      ) : (
        <ul>
          <li
            className="flex py-3 cursor-pointer transition-all hover:opacity-50 lg:hidden"
            onClick={toggleCandidateAuth}>
            <Icon name="person" className="mr-2" />
            <p>I'm a candidate</p>
          </li>
          <li
            className="flex py-3 cursor-pointer transition-all hover:opacity-50"
            onClick={toggleProviderAuth}>
            <Icon name="med-cross" className="mr-2" />
            <p className="hidden lg:block" data-cy="open-provider-auth-button">
              Provider login
            </p>
            <p className="flex lg:hidden">I'm a provider</p>
          </li>
          <li>
            <AeditLink
              className="flex py-3 transition-all hover:opacity-50"
              pathname="/for-providers">
              <Icon name="map-pin" className="mr-2" />
              <p>List your practice</p>
            </AeditLink>
          </li>
        </ul>
      )}
    </DropdownMenu.Box>
  </DropdownMenu>
)

export default AuthMenu
